exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-alumni-update-jsx": () => import("./../../../src/pages/alumni/update.jsx" /* webpackChunkName: "component---src-pages-alumni-update-jsx" */),
  "component---src-pages-calendar-jsx": () => import("./../../../src/pages/calendar.jsx" /* webpackChunkName: "component---src-pages-calendar-jsx" */),
  "component---src-pages-challenge-jsx": () => import("./../../../src/pages/challenge.jsx" /* webpackChunkName: "component---src-pages-challenge-jsx" */),
  "component---src-pages-excellence-jsx": () => import("./../../../src/pages/excellence.jsx" /* webpackChunkName: "component---src-pages-excellence-jsx" */),
  "component---src-pages-give-jsx": () => import("./../../../src/pages/give.jsx" /* webpackChunkName: "component---src-pages-give-jsx" */),
  "component---src-pages-index-x-jsx": () => import("./../../../src/pages/index_X.jsx" /* webpackChunkName: "component---src-pages-index-x-jsx" */),
  "component---src-pages-inquiry-jsx": () => import("./../../../src/pages/inquiry.jsx" /* webpackChunkName: "component---src-pages-inquiry-jsx" */),
  "component---src-pages-news-jsx": () => import("./../../../src/pages/news.jsx" /* webpackChunkName: "component---src-pages-news-jsx" */),
  "component---src-pages-summercamps-x-jsx": () => import("./../../../src/pages/summercamps_X.jsx" /* webpackChunkName: "component---src-pages-summercamps-x-jsx" */),
  "component---src-pages-thank-you-jsx": () => import("./../../../src/pages/thank-you.jsx" /* webpackChunkName: "component---src-pages-thank-you-jsx" */),
  "component---src-templates-page-template-jsx": () => import("./../../../src/templates/pageTemplate.jsx" /* webpackChunkName: "component---src-templates-page-template-jsx" */)
}

